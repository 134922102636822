<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-card>
                  <label style="margin-left: 5px" for="sample_number">{{ $t('farmerOperator.sample_number') }} : </label>
                  <span id="sample_number" style="color: #333; font-weight: bold; margin-left: 5px;"> {{ item.sample_number }}</span>
                </b-card>
              </b-row>
              <b-row>
                <b-col xl="12" md="12" lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                    <b-form  @submit.prevent="handleSubmit(register)">
                      <b-row>
                        <b-col xl="6" md="6" lg="6" sm="12">
                          <ValidationProvider name="Memo no" vid="memo_no">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="memo_no"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('irriWaterTest.report_memo_no')}}
                              </template>
                              <b-form-input
                                id="memo_no"
                                v-model="waterTestReport.memo_no"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" md="6" lg="6" sm="12">
                          <ValidationProvider name="Attachment" vid="attachment">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="attachment"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{ $t('committee.attachment') }}  <span class="text-danger">*</span>
                              </template>
                              <b-form-file
                              id="attachment"
                              @change="onChangeFile"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-file>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                      </b-row>
                      <b-row>
                        <b-col xl="6" md="6" lg="6" sm="12">
                          <ValidationProvider name="As ppm" vid="as_ppm">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="as_ppm"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('irriWaterTest.as_ppm')}}
                              </template>
                              <b-form-input
                                id="as_ppm"
                                v-model="waterTestReport.as_ppm"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" md="6" lg="6" sm="12">
                          <ValidationProvider name="Ca ppm" vid="ca_ppm">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="ca_ppm"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('irriWaterTest.ca_ppm')}}
                              </template>
                              <b-form-input
                                id="ca_ppm"
                                v-model="waterTestReport.ca_ppm"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" md="6" lg="6" sm="12">
                          <ValidationProvider name="Mg ppm" vid="mg_ppm">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="mg_ppm"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('irriWaterTest.mg_ppm')}}
                              </template>
                              <b-form-input
                                id="mg_ppm"
                                v-model="waterTestReport.mg_ppm"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" md="6" lg="6" sm="12">
                          <ValidationProvider name="Fe ppm" vid="fe_ppm">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="fe_ppm"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('irriWaterTest.fe_ppm')}}
                              </template>
                              <b-form-input
                                id="fe_ppm"
                                v-model="waterTestReport.fe_ppm"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" md="6" lg="6" sm="12">
                          <ValidationProvider name="Ci ppm" vid="ci_ppm">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="ci_ppm"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('irriWaterTest.ci_ppm')}}
                              </template>
                              <b-form-input
                                id="ci_ppm"
                                v-model="waterTestReport.ci_ppm"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" md="6" lg="6" sm="12">
                          <ValidationProvider name="Total Hardness ppm" vid="total_hardness_ppm">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="total_hardness_ppm"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('irriWaterTest.total_hardness_ppm')}}
                              </template>
                              <b-form-input
                                id="total_hardness_ppm"
                                v-model="waterTestReport.total_hardness_ppm"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" md="6" lg="6" sm="12">
                          <ValidationProvider name="Ph" vid="ph">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="ph"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('irriWaterTest.ph')}}
                              </template>
                              <b-form-input
                                id="ph"
                                v-model="waterTestReport.ph"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" md="6" lg="6" sm="12">
                          <ValidationProvider name="Ec psm" vid="ec_psm">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="ph"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('irriWaterTest.ec_psm')}}
                              </template>
                              <b-form-input
                                id="ec_psm"
                                v-model="waterTestReport.ec_psm"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" md="6" lg="6" sm="12">
                          <ValidationProvider name="TDS" vid="tds">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="tds"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('irriWaterTest.tds')}}
                              </template>
                              <b-form-input
                                id="tds"
                                v-model="waterTestReport.tds"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" md="6" lg="6" sm="12">
                          <ValidationProvider name="Turbidity" vid="turbidity">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="turbidity"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('irriWaterTest.turbidity')}}
                              </template>
                              <b-form-input
                                id="turbidity"
                                v-model="waterTestReport.turbidity"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" md="6" lg="6" sm="12">
                          <ValidationProvider name="DO" vid="do">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="do"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('irriWaterTest.do')}}
                              </template>
                              <b-form-input
                                id="do"
                                v-model="waterTestReport.do"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col xl="6" md="6" lg="6" sm="12">
                          <ValidationProvider name="FC" vid="fc">
                            <b-form-group
                              class="row"
                              label-cols-sm="5"
                              label-for="fc"
                              slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                  {{$t('irriWaterTest.fc')}}
                              </template>
                              <b-form-input
                                id="fc"
                                v-model="waterTestReport.fc"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary">{{ saveBtnName}}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('collect-report')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { waterTestingRequestReportCollect } from '../../api/routes'

export default {
  props: ['waterTestId', 'item'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      waterTestReport: {
        far_water_test_apps_id: '',
        memo_no: '',
        attachment: null,
        as_ppm: '',
        ca_ppm: '',
        mg_ppm: '',
        fe_ppm: '',
        ci_ppm: '',
        total_hardness_ppm: '',
        ph: '',
        ec_psm: '',
        tds: '',
        turbidity: '',
        do: '',
        fc: ''
      },
      attachmentDemo: [],
      laboratoryList: [],
      userList: [],
      user: [],
      waterTestRequestList: {}
    }
  },
  computed: {
    organizationtList: function () {
      const listObject = this.$store.state.commonObj.organizationProfileList
      return listObject
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    onChangeFile (e) {
      this.attachmentDemo = e.target.files[0]
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
      this.waterTestReport.far_water_test_apps_id = this.item.id
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData()
      Object.keys(this.waterTestReport).map(key => {
        if (key === 'attachment') {
          formData.append(key, this.attachmentDemo)
        } else {
          formData.append(key, this.waterTestReport[key])
        }
      })
      result = await RestApi.postData(irriSchemeServiceBaseUrl, waterTestingRequestReportCollect, formData, config)
      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false
      if (result.success) {
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('collect-report')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
